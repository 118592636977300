import { useContext, useEffect, useState } from "react"
import CategoryDB from "../../../../database/wrappers/category"
import StoreCategoryDB from "../../../../database/wrappers/storeCategory"
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../bootstrap/Modal"
import TableCustom from "../../../MyCustom/TableCustom"
import { DefaultContext } from "../../../../contexts/default"
import { orderBy } from "firebase/firestore"
import Button from "../../../bootstrap/Button"
import Popovers from "../../../bootstrap/Popovers"
import api from "../../../../services/api"
import Spinner from "../../../bootstrap/Spinner"
import PreAlert from "../../../../helpers/utils/preAlert"

const ModalCategories = ({ open, handleOpen, handleNext, setCategorySelected }) => {
    const { establishment, store, storeInfo, onShowAlert } = useContext(DefaultContext);

    const [categories, setCategories] = useState([])
    const [storeCategoriesDicionary, setstoreCategoriesDicionary] = useState([])
    const [categoriesRender, setCategoriesRender] = useState([])
    const [loading, setloading] = useState(false);
    const [loadingRappi, setloadingRappi] = useState(false);


    useEffect(() => {
        if (establishment)
            new CategoryDB(establishment.id)
                .getAll(orderBy('name', 'asc'))
                .then((data) => {
                    setCategories(data)
                })
    }, [establishment])

    useEffect(() => {
        if (!establishment || !store) return;
        const onSubscribe = new StoreCategoryDB(establishment.id, store.id).on(datas => {
            const dicionary = {};
            datas.forEach(data => dicionary[data.id] = data)
            setstoreCategoriesDicionary(dicionary)
        });
        return onSubscribe;
    }, [establishment, store])

    useEffect(() => {
        if (categories !== null && storeCategoriesDicionary !== null) {
            const categoriesRender = categories.map(categorie => {
                const storeCategorie = storeCategoriesDicionary[categorie.id];
                return {
                    image_url: categorie.image_url,
                    id: categorie.id,
                    name: categorie.name,
                    delivery_code: categorie?.delivery_code,
                    ifoodId: storeCategorie?.ifoodId,
                    toRappi: storeCategorie?.toRappi,
                    products: categorie.products,
                    subCategories: categorie.subCategories
                }
            })
            setCategoriesRender(categoriesRender);
        }
    }, [categories, storeCategoriesDicionary])

    const onError = (data) => {
        onShowAlert(PreAlert.error(data))
    }

    const HandleStatus = async (row) => {
        setloading(true);
        if (!row.ifoodId) {
            await api.post('routesIfood/registerCategory ',
                { storeInfo, categorieInfo: { name: row.name, id: row.id } })
                .then(() => { })
                .catch(() => { })
                .finally(() => setloading(false))
        } else if (row.ifoodId) {
            await api.post('routesIfood/deleteCategory',
                { storeInfo, categorieInfo: { ifoodId: row.ifoodId, id: row.id } })
                .then(() => { })
                .catch((error) => { onError(error?.response?.data) })
                .finally(() => setloading(false))
        }
    }

    const onChangeStatusRappi = async (row) => {
        setloading(true);
        if (!row.toRappi) {
            await api.post('rappiRoutes/registerMenu ',
                { storeInfo, categorieInfo: { name: row.name, id: row.id } })
                .then(() => { })
                .catch(() => { })
                .finally(() => setloading(false))
        }
    }

    const toggleRappiStatus = (row) => {
        if(!row.toRappi){
            return new StoreCategoryDB(establishment.id, store.id).define(row.id, { toRappi: !row?.toRappi }) 
        }
        else {
            return new StoreCategoryDB(establishment.id, store.id).update(row.id, { toRappi: !row?.toRappi })
        }
    };

    const HandleRegisterMenu = async () => {
        setloadingRappi(true);
        if (!store.id_store_rappi) return onShowAlert(PreAlert.error("Registre as credenciais do Rappi."))

        await api.post('routesRappi/registerMenu', { storeInfo })
        setloadingRappi(false);
    }




    const columns = [
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'Delivery-code',
            field: 'delivery_code',
        },
        {
            label: 'Ifood Status',
            field: 'ifoodId',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.ifoodId ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => HandleStatus(row)}
                                className='text-nowrap'>
                                {row.ifoodId ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Rappi Status',
            field: 'toRappi',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.toRappi ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => toggleRappiStatus(row)}
                                className='text-nowrap'>
                                {row.toRappi ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Ações',
            format: row => (
                <>
                    <Popovers
                        trigger="hover"
                        title="Registrar Produtos"
                    >
                        <Button
                            isLink
                            color="dark"
                            icon='Pen'
                            onClick={() => {
                                setCategorySelected(row);
                                handleOpen(false)
                                handleNext(true)
                            }}
                        >
                        </Button>
                    </Popovers>
                </>
            )
        }
    ]

    return (
        <>
            <Modal
                id={'modal-categories'}
                titleId={'Cadastro Categorias'}
                isOpen={open}
                setIsOpen={handleOpen}
                isStaticBackdrop={true}
                isScrollable={false}
                isCentered={true}
                size="xl" // 'sm' || 'lg' || 'xl' 
                isAnimation={true}
                onSubmit={() => { }}
            >
                <ModalHeader
                    setIsOpen={() => handleOpen(false)}
                >
                    <ModalTitle id="register-item">Cadastro de Categorias</ModalTitle>
                </ModalHeader>
                <ModalBody className='p-3'>
                    <TableCustom
                        columns={columns}
                        rows={categoriesRender}
                        fileName={'Categoria-de-Gerenciamento'}
                    />
                </ModalBody>
                <ModalFooter>
                    {!loadingRappi ?
                        <Button
                            color='info'
                            rounded={1}
                            hoverShadow="sm"
                            shadow="sm"
                            size="sm"
                            onClick={HandleRegisterMenu}
                        >
                            Cadastrar Rappi
                        </Button>
                        : <Spinner />}
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalCategories