import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../bootstrap/Button';
import TableCustom from '../../../MyCustom/TableCustom';
import { DefaultContext } from '../../../../contexts/default';
import { orderBy } from 'firebase/firestore';
import Money from '../../../../helpers/utils/money/money';
import ComplementDB from '../../../../database/wrappers/complement';
import StoreComplementDB from '../../../../database/wrappers/storeComplement';
import api from '../../../../services/api';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../../../bootstrap/Modal';
import Logo from "../../../Logo";
import Spinner from '../../../bootstrap/Spinner';

export const Complements = ({ open, handleOpen, handleBack, categoryComplementSelected }) => {
    const { store, establishment, storeInfo } = useContext(DefaultContext);

    const [complements, setComplements] = useState([])
    const [storeComplementsDicionary, setStoreComplementsDicionary] = useState([])
    const [complementsRender, setComplementsRender] = useState([])
    const [loading, setloading] = useState(false);

    useEffect(() => {
        if (establishment && categoryComplementSelected)
            new ComplementDB(establishment.id)
                .getAll(orderBy('name', 'asc'))
                .then((data) => {
                    setComplements(data.filter((comp) => categoryComplementSelected?.complements?.includes(comp.id)))
                })
    }, [establishment, categoryComplementSelected])

    useEffect(() => {
        if (!establishment || !store) return;
        const onSubscribe = new StoreComplementDB(establishment.id, store.id).on(datas => {
            const dicionary = {};
            datas.forEach(data => dicionary[data.id] = data)
            setStoreComplementsDicionary(dicionary)
        });
        return onSubscribe;
    }, [establishment, store])

    useEffect(() => {
        if (complements !== null && storeComplementsDicionary !== null) {
            const complementsRender = complements.map(complement => {
                const storeComplement = storeComplementsDicionary[complement.id];
                return {
                    id: complement.id,
                    name: complement.name,
                    delivery_code: complement.delivery_code,
                    price: storeComplement?.delivery_price || storeComplement?.price || complement.price,
                    ifoodId: storeComplement?.ifoodId,
                    toRappi: storeComplement?.toRappi,
                    image_url: complement?.image_url
                }
            })
            setComplementsRender(complementsRender);
        }
    }, [complements, storeComplementsDicionary])

    const HandleStatus = (row) => {
        if (!row.ifoodId) {
            api.post('routesIfood/registerComplement ',
                { storeInfo, complementInfo: { name: row.name, id: row.id } })
        } else if (row.ifoodId) {
            api.post('routesIfood/deleteComplement',
                { storeInfo, complementInfo: { ifoodId: row.ifoodId, id: row.id } })
        }
    }

    const HandleStatusRappi = async (row) => {
        if (!row.toRappi) {
            await api.post('rappiRoutes/registerMenu',
                { storeInfo, categorieInfo: { name: row.name, id: row.id } })
        }
    }

    const toggleRappiStatus = (row) => {
        if(!row.toRappi){
            return new StoreComplementDB(establishment.id, store.id).define(row.id, { toRappi: !row?.toRappi })
        }
        else {
            return new StoreComplementDB(establishment.id, store.id).update(row.id, { toRappi: !row?.toRappi })
        }
    };

    const columns = [
        {
            label: 'Imagem',
            field: 'image_url',
            format: row => (
                <>
                    {row.image_url ? (
                        <img src={row.image_url} alt="Imagem" width={54} height={54} />
                    ) : (
                        <Logo width={54} black />
                    )}
                </>
            )
        },
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'Delivery-code',
            field: 'delivery_code',
        },
        {
            label: 'Preco',
            field: 'price',
            format: row => (
                <span>{Money.centsToMaskMoney(row.price)}</span>
            ),
            formatExport: value => Money.centsToCoin(value)
        },
        {
            label: 'Ifood Status',
            field: 'ifoodId',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.ifoodId ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => HandleStatus(row)}
                                className='text-nowrap'>
                                {row.ifoodId ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Rappi Status',
            field: 'toRappi',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.toRappi ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => toggleRappiStatus(row)}
                                className='text-nowrap'>
                                {row.toRappi ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
    ]

    return (
        <Modal
            id={'modal-complements'}
            titleId={'Cadastro Complements'}
            isOpen={open}
            setIsOpen={handleOpen}
            isStaticBackdrop={true}
            isScrollable={false}
            isCentered={true}
            size="xl" // 'sm' || 'lg' || 'xl' 
            isAnimation={true}
            onSubmit={() => { }}
        >
            <ModalHeader
                setIsOpen={() => handleOpen(false)}
            >
                <ModalTitle id="register-item">Cadastro de Complementos</ModalTitle>
            </ModalHeader>
            <ModalBody className='p-3'>

                <TableCustom
                    columns={columns}
                    rows={complementsRender}
                    fileName={'complement-delivery'}
                />

            </ModalBody>
            <ModalFooter>
                <Button
                    color='info'
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                    onClick={handleBack}
                >
                    Voltar
                </Button>
            </ModalFooter>
        </Modal>

    )
}