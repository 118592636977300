import { QueryConstraint, Timestamp, Unsubscribe } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";
import User from "../../interface/user";
import Money from "../../helpers/utils/money/money";

class UserHistory extends FirestorePipe {


    public static readonly colName = 'UsersHistory'

    private estabId: string;
    private user: User;
    private storeName: string | undefined;
    private collectionName: string | undefined;

    constructor(estabId: string, user: User, collectionName: string, storeName?: string) {
        if (!estabId)
            throw new Error('Informe o id do estabelecimento')

        super(`${UserHistory.colName}`);

        this.estabId = estabId;
        this.user = user;
        this.storeName = storeName;
        this.collectionName = collectionName;
    }

    public create(data: any): Promise<any> {
        const logData = {
            action: "Create",
            created_at: Timestamp,
            estabId: this.estabId,
            userId: this.user.uid,
            message: `Criou ${this.collectionName} ${data.name}`
        }
        return this._create(logData);
    }

    update(id: string, { selected, data }: { selected: any, data?: any }): Promise<any> {
        let message = `Editou ${this.collectionName} ${selected.name}${this.storeName ? ` na Loja ` + this.storeName : ``}`

        let actions = [];

        if (data.active != null && selected.active != data.active) {
            console.log("f1")
            actions.push(`${data.active == true ? "Ativou " : "Desativou "}`);
        }

        if (data && data.price != selected.price) {
            console.log("f2")
            actions.push(`Editou o preço para ${Money.centsToMaskMoney(data.price||selected.price)} d`);
        }

        if (actions.length > 0) {
            console.log("f3")
            message = `${actions.join('e ')}${this.collectionName} ${selected.name}${this.storeName ? ` na Loja ` + this.storeName : ''}`;
        }

        const logData = {
            action: "Edit",
            created_at: Timestamp,
            estabId: this.estabId,
            userId: this.user.uid,
            ...(message && { message }),
            ...(data && { data })
        }
        return this._create(logData);
    }

    deleteWithData(id: string, data: any): Promise<any> {
        const logData = {
            action: "Delete",
            created_at: Timestamp,
            estabId: this.estabId,
            userId: this.user.uid,
            message: `Deletou ${this.collectionName} ${data.name}`
        }
        return this._create(logData);
    }

    getAll(...params: QueryConstraint[]): Promise<any[]> {
        throw new Error("Method not implemented.");
    }
    get(id: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    on(listener: (data: any[]) => void): Unsubscribe {
        throw new Error("Method not implemented.");
    }

    delete(id: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
}

export default UserHistory;