import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Button from '../../bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
// Database
import { where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import ProductDB from '../../../database/wrappers/product';
import SubCategoryDB from '../../../database/wrappers/subCategory';
import PreAlert from '../../../helpers/utils/preAlert';
import Avatar from '../../MyCustom/Avatar';
import ListProducts from '../../MyCustom/ListProducts';
import Nav, { NavItem } from '../../bootstrap/Nav';
import ListSubCategories from '../Categories/ListSubCategories';
import UserHistory from '../../../database/wrappers/userHistory';


const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (isNaN(Number(values.order)) || Number(values.order) < 0 || Number(values.order) > 99) {
    errors.order = "Informe um número entre 0 e 99";
  }


  if (!values.active) {
    errors.active = 'Você precisa escolher o status da subcategoria';
  }

  return errors;
}

const ModalRegisterSubcategories = ({ open, setIsOpen, setIsClose, subCategorySelected }) => {

  const { establishment, onShowAlert, user } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [products, setproducts] = useState([]);
  const [abaSelected, setabaSelected] = useState(0);
  const [subCategoriesResponse, setSubCategoriesResponse] = useState([])



  useEffect(() => {
    if (!open) return formik.resetForm();
    if (subCategorySelected) {
      const { name, active, products, order, image_url, subCategories, useSubCategories } = subCategorySelected;

      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        products,
        order,
        image: null,
        image_url,
        subCategories: subCategories !== undefined ? subCategories : [],
        useSubCategories: useSubCategories !== undefined ? useSubCategories : false,
      });
    }
  }, [subCategorySelected, open])

  useEffect(() => {
    if (establishment) {
      function sort(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
      async function getProducts() {
        const products = await new ProductDB(establishment.id).getAll(where('active', '==', true));
        products.sort(sort);
        setproducts(products);
      }

      async function getSubCategories() {
        const response = await new SubCategoryDB(establishment.id).getAll(where('active', '==', true))
        response.sort(sort);
        console.log(response)
        setSubCategoriesResponse(response)

      }

      getProducts();
      getSubCategories();
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      order: 0,
      products: [],
      subCategories: [],
      image: null,
      image_url: null,
      useSubCategories: false,
    },
    validate,
    onSubmit: async values => {
      const { name, active, order, products, image, subCategories, useSubCategories, } = values;

      const data = {
        name: name,
        active: active === 'ativo',
        order: Number(order),
        products,
        image,
        subCategories: subCategories !== undefined ? subCategories : [],
        useSubCategories: useSubCategories !== undefined ? useSubCategories : false,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (subCategorySelected) {
        new SubCategoryDB(establishment.id)
          .update(subCategorySelected.id, data)
          .then(() => {
            onSuccessUpdate();
            new UserHistory(establishment.id, user, "a Sub Categoria")
              .update(subCategorySelected.id, { data, selected: subCategorySelected })
              .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new SubCategoryDB(establishment.id)
          .create(data)
          .then(() => {
            onSuccess();
            new UserHistory(establishment.id, user, "a Sub Categoria")
              .create(data)
              .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const onChangeShowCategory = useCallback((show) => setshowCategoryItems(show), [])
  const [showCategoryItems, setshowCategoryItems] = useState(false)

  const handleChangeOrder = useCallback(e => {
    const order = e.target.value.replace(/\D/g, "");
    formik.setValues({
      ...formik.values,
      order
    })
  }, [formik.values])

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-sub-category'}
      titleId={'Cadastro de SubCategoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-sub-category">{subCategorySelected ? 'Atualização de SubCategoria' : 'Cadastro de SubCategoria'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='row align-items-center justify-content-center'>
            <FormGroup
              id='image_url'
            >
              <Avatar
                id='image_url'
                value={formik.values.image_url}
                onChange={handleImage} />
            </FormGroup>
          </div>
          {/* Inputs */}
          <div className="row  pb-2 d-flex">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>


            {/* Ordem */}
            <FormGroup id="order" label="Ordem" className='col-md-4 mb-4'>
              <Input
                onChange={handleChangeOrder}
                onBlur={formik.handleBlur}
                value={formik.values.order || ''}
                isValid={formik.isValid}
                isTouched={formik.touched.order}
                invalidFeedback={formik.errors.order}
                validFeedback='Assim está bom!'
                placeholder='1, 2, 3, 4, etc...'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup className='col-md-4 mb-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>

            <FormGroup className='col-md-6 mb-4'>
              <Label>Usar sub-categorias</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.useSubCategories}
                invalidFeedback={formik.errors.useSubCategories}
              >
                <Checks
                  type="switch"
                  id="useSubCategories"
                  label="Permitir adicionar sub-categorias"
                  name="useSubCategories"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.useSubCategories}
                  isInline
                />
              </ChecksGroup>

            </FormGroup>
          </div>

          <Nav
            style={{ marginTop: 20 }}
            tag='ul' // 'ul' || 'nav'
            design='tabs' // 'tabs' || 'pills'
            isFill
          >
            <NavItem
              isActive={abaSelected === 0}
              onClick={() => setabaSelected(0)}>
              <a>
                Produtos
              </a>
            </NavItem>

            {formik.values.useSubCategories &&
              <NavItem
                isActive={abaSelected === 1}
                onClick={() => setabaSelected(1)}>
                <a>
                  Sub-categorias
                </a>
              </NavItem>
            }

          </Nav>
          {/* List */}
          <ListProducts
            hidden={abaSelected !== 0}
            products={products}
            productsSelected={formik.values.products}
            onChange={formik.handleChange}
          />

          <ListSubCategories
            hidden={abaSelected !== 1}
            products={products}
            categories={subCategoriesResponse.filter((sub) => sub.id !== subCategorySelected?.id)}
            categoriesSelected={formik.values.subCategories ?? []}
            onChange={formik.handleChange}
            onChangeShowCategory={onChangeShowCategory}
          />
          {/* Buttons */}
          <div className="row pt-5">

            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <Button
                  color="success"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  isDisable={!formik.isValid && !!formik.submitCount}
                  type='submit'
                  color='success'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  Confirmar
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterSubcategories;